import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "~/lib/utils";

export const inputVariants = cva(
  "flex w-full rounded-md py-2 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "border border-stone-400 bg-transparent focus:ring-stone-400 dark:border-stone-600 dark:bg-stone-900 placeholder:text-gray-9 dark:focus:ring-stone-600 focus:ring-offset-transparent",
        search:
          "border border-stone-400 bg-transparent dark:border-stone-600 focus-within:ring-2 focus-within:ring-offset-1 focus-within:ring-stone-400 dark:focus-within:ring-stone-600 focus-within:ring-offset-transparent",
      },
      inputSize: {
        default: "h-10",
        lg: "h-12 px-4 sm:text-xl sm:px-6 sm:h-14",
        textarea: "",
      },
    },
    defaultVariants: {
      variant: "default",
      inputSize: "default",
    },
  }
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ variant, inputSize, className, ...props }, ref) => {
    return (
      <input
        className={cn(inputVariants({ variant, inputSize, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);

Input.displayName = "Input";

export { Input };
